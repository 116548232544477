.spinning {
    font-size: 3em;
    animation: spin .8s infinite linear;
    height: 100%;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}
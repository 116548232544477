@font-face {
  font-family: 'Coolvetica';
  src: local('Coolvetica'), url(../fonts/coolvetica.ttf) format('ttf');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

  .contentdiv {
    margin-top: 100px;
    display: Flex;
    flex-direction: column;
    width: 100%;

  }
  .upper {
    height: fit-content;
  }

  .upper h2{
    margin: 5px;
    padding: 0;
    font-size: 1.7em;
    font-family: 'Coolvetica', Helvetica, sans-serif;
    font-weight: normal;
    text-align: left;
}

.projects {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 30px;
  padding-top: 0;
  padding-bottom: 0;
  min-height: 60vh;
  height: fit-content;
  background: transparent;
}
.wideSection {
  padding: 20px;
}
.wideCard {
  cursor: pointer;
  border-radius: 15px;
  flex: 1;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.384);

  padding: 15px;
  text-align: left;
}

.wideCard a {
  color: rgba(117, 117, 117, 0.938);
}


@media only screen and (min-width: 600px) {
  .projects {
    padding: 300px;
    padding-top: 0;
    padding-bottom: 0;
  }
}
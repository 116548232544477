.skills ul {
    display: flex;
    align-items: center;
    padding:20px;
    list-style-type: none;
}

.badge{
    overflow: visible;
    height: 50px;
    min-width: 50px;
}
.badge img{

    margin: 4px;
    height: 50px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.479);
    border-radius: 50px;
    transition: all .1s ease-in-out;
}

.badge img:hover{
    transform: translateY(-7px);
    box-shadow: 2px 7px 15px rgba(0, 0, 0, 0.616);
}
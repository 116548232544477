.commentsection {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.commentsection div {
    text-align: left;
    width: 900px;
}

.commentform{
    padding: 30px;
    font-family: 'Poppins', sans-serif;
    display: flex;
    gap: 4px;
    flex-direction: column;
    font-size: 1em;
}
.commentform input {
    padding: 5px;
    width: 50%;
    overflow:hidden;
    font-family: 'Poppins', sans-serif;
    word-wrap: break-word;
}
.commentform textarea {
    font-family: 'Poppins', sans-serif;
    font-size: 1em;
    max-width: 100%;
}
.commentform button {
    font-size: 1.3em;
    width: fit-content;
}

.comment {
    /* border: 1px solid rgb(214, 214, 214); */
    padding: 6px;
    margin-top: 12px;
    /* border-radius: 12px; */
    background-color: rgb(255, 255, 255);
    /* box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.281); */
    border-bottom: 1px solid lightgray;
}

.comment h3, p {
    margin: 3px;
}
.comment h3 span{
    font-size: small;
    font-weight: lighter;
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600&display=swap');

.blog {
    scroll-behavior: smooth;
    font-family: 'Poppins', sans-serif;
}

@media screen and (max-width: 850px) {
    .navbar img{
        height: 40px
    }

    .welcomhero{
        flex-direction: column;
        flex-direction: column-reverse;
    }
    .welcomheader {
        z-index: 2;
        padding-left: 10px;
    }
    .coffee{
        height: 30vh;
    }
}
.blogpost {
    width: 100%;
    scroll-behavior: smooth;
}

.blogpost_top {
    background: linear-gradient(rgb(255, 255, 255), black);
}
.blogpost_top > div{
    display: flex;
    align-items: flex-end;
    color: rgb(231, 231, 231);
    height: 100%;
    min-height: 500px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    text-align: left;
    background-attachment: fixed;
}

.blogtitle {
    font-size: 3em;
    font-weight: bold;
    margin-top: 0;
}
.blogpost_top_details{
    background: linear-gradient(rgba(255, 255, 255, 0), black);
    color: rgb(255, 255, 255);
    padding: 50px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.blogpost_top_details p{
    margin: 0;
}
.namedate{
    flex-wrap: nowrap;
}
.blogpostContentContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.blogContent {
    align-items: center;
    width: 800px;
    text-align: left;
}
.blogContent > p {
    border-bottom: 1px solid rgba(128, 128, 128, 0.342);
}
html, body, ul{
    padding: 0;
    margin: 0;
    border-collapse: collapse;
    box-sizing: border-box;
    overflow-y: visible;
}


.togglerDiv.mobile{
    position: fixed;
    width: 50px;
    height: 50px;
    /* padding: 3px; */
    border-radius: 100px;
    z-index: 4;
    margin: 5px;
    top: 5px;
    right: 5px;
    background: linear-gradient(-19deg, rgba(0,87,224,1) 0%, rgba(2,250,239,1) 100%);
    box-shadow: 2px 2px 6px black;
    display: flex;
    align-items: center;
    justify-content: center;
}

.togglerDiv.mobile > * {
    font-size: 30px;
}

/* styles for the top of menu frame */
.frametop {
    height: 80px;
    width: calc(100% - 80px);
    display: none;
    flex-direction: row;
    justify-content: space-between;
}
.frametop div {
    display: flex;
}
.frametop ul {
    width: 100%;
    list-style: none;
    display: flex;
    align-items: flex-end;
}
.frametop li {
    padding-right: 2em;
    font-size: .8em;
    font-family: 'Courier New', Courier, monospace;
    text-decoration: underline;
}

.togglerDiv {
    display: flex;
    align-items: center;
}
.togglerDiv small{
    font-size: .8em;
}

/* styles for midframe (content and social icons bar) */

.midframe {
    display: flex;
    flex-direction: row;
    /* overflow: hidden; */

}

/* styles for content */
.content {
    border: 2px solid;
    min-height: fit-content;
    display: flex;
    flex-direction: column;
    background: transparent;
    overflow: scroll;
    overflow-x: hidden;
    width: 100%;
    scroll-behavior: smooth;
}

/* width */
.content::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  .content::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  .content::-webkit-scrollbar-thumb {
      border-radius: 20px;
    background: linear-gradient(-19deg, rgba(0,87,224,1) 0%, rgb(2, 200, 250) 100%);

  }
  
  /* Handle on hover */
  .content::-webkit-scrollbar-thumb:hover {
    opacity: .7;
  }


/* styles for socicons bar */
.sociconsulFrame {
    display: none;
    list-style-type: none;
    width: 80px;
}
.sociconsulFrame li {
    margin: 5px;
    font-size: .7em;
}

a {
    text-decoration: none;
}


/* styles for lower frame */
.lowerframe {
    display: none;
    height: 80px;
    width: 100%;
    font-family: 'consolas', 'Courier New', Courier, monospace;
}
.navmenu {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    border-left: 2px solid;
}
.navmenu li{
    box-sizing: border-box;
    border-collapse: collapse;
    height: 80px;
    width: 100px;
    cursor: pointer;
    font-size: 1em;
    
    border: 2px solid;
    border-top: none;
    border-left: none;
    border-bottom: none;
    display: flex;
    align-items: center;
}

.navmenu li > * {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    width: 100px;
}

.desktop{
    visibility: hidden;
}
.socicon {
    
    font-size: 3.3em;
}

.active {
    transition: all .3s ease-in-out;
    background-color: rgba(112, 112, 112, 0.541)
}

@media only screen and (min-width: 600px) {
    html, body {
        /* overflow: hidden; */
    }
    .mobile{
        display: none;
    }
    .desktop {
        visibility: visible;
    }
    .frametop {
        display: flex;
    }
    .sociconsulFrame{
        display: block;
    }
    .lowerframe {
        display: block;
        /* position: fixed;
        bottom: 0; */
    }
    .mainframe {
        display: block;
        padding-left: 80px;
    }
    .content{
        height: calc(100vh - 160px);
    }
}



@font-face {
    font-family: 'Coolvetica';
    src: local('Coolvetica'), url(../fonts/coolvetica.ttf) format('ttf');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

.contentdiv {
    /* height: calc(100vh - 100px); */
    min-height: 100vh;
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    
    justify-content: space-evenly;
}

.upper {
    height: fit-content;
    border-bottom: 2px solid rgba(128, 128, 128, 0.562);
    display: flex;
    flex-direction: column;
    align-items: left;
    /* width: 100%; */
    width: 100%;
}

.headline {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    /* justify-content: space-between;
    align-items: flex-end; */
}
.info {
    min-height: fit-content;
}
.svgContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.nathanSvg {
    /* transform: translateX(-150px); */
    width: 50%;
    max-width: 350px;
}

.upper h1{

    margin: 0;
    padding: 0;
    margin-left: 15px;
    font-size: 4em;
    height: fit-content;
    width: fit-content;
    font-family: 'Coolvetica', Helvetica, sans-serif;
    font-weight: normal;
    text-align: left;
    border-bottom: 2px solid;
    white-space: nowrap;
}

.upper h3{
    padding-left: 15px;
    font-family: consolas, monospace;
    display: flex;
    
}
.upper h3 a {
    padding: 15px 20px;
    border: 2px solid;
}


.lower {
    display: none;
    justify-content: space-evenly;
    align-items: center;
}

.skillsmobile {
    font-weight: bold;
    text-align: left;
    padding-left: 15px;
    list-style-type: none;
}

.skillsmobile li {
    font-weight: bold;
}

.lower ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    list-style-type: none;
    font-weight: bold;
    font-size: 1.2em;
}

.brief {
    width: 300px;
    padding: 3em;
    box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.253);
    text-align: left;
    font-size: 1em;
}

.brief a{
    font-weight: bold;
    text-decoration: underline;

}

.content {
    align-items: flex-start;
}



@media only screen and (min-width: 700px) {
    .contentdiv {
        margin-top: 50px;

    }
    .upper h1{
        /* font-size: 2em; */
        border: none;

    }
    .headline {
        
        /* align-items: flex-start; */
    }
    .lower {
        display: flex;
    }
    .mobile {
        display: none;
    }
    .skillsmobile {
        display: none;
    }
}

@media only screen and (min-width: 1350px) {
    .upper h1 {
        font-size: 8em;
    }
    .headline {
        flex-direction: row;
        align-items: center;
        
    }
    .nathanSvg {
        width: 350px;
    }
}
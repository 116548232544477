.blogwelcome{
    padding-top: 10vh;
    min-height: 80vh;
    background: linear-gradient(#00496b, #01a4f2);
    text-align: left;
    display: flex;
    justify-content: center;
    color: white;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.609);
}

.welcomhero{
    width: 800px;
    display: flex;
    align-items: center;
    place-content: center;
    gap: 40px;
    height: fit-content;
}

.welcomheader {
    display: flex;
    flex-direction: column;
    justify-content:center;
}

.welcomheader h1 {
    text-align: left;
    font-size: 2em;
}

.coffee img{
    height: 70vh;
}

/* .contentdiv {
    display: block;
    height: calc(100vh - 100px);
} */
.cardMain{
    width: 300px;
    height: 380px;
    display: flex;
    overflow: visible;
}
.card {
    cursor: pointer;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.473);
    width: 300px;
    overflow: hidden;
    margin: 0;
    border-radius: 8px;
    margin-top: 10px;
    /* transition: all .3s ease-in-out; */
    will-change: transform;
}
.runLink{
    color: white;
}
.projImg{
    transition: all .3s ease-in-out;
    width: 300px;
    height: 150px;

    border-radius: 8px 8px 0 0;
}
.info {
    transition: all .3s ease-in-out;
}
.card:hover .projImg {
    opacity: .2;
}
.card:hover .info {
    transform: translateY(-80px);
}

.card:hover .actionContainer{
    opacity: 1;
}

.technologiesul{
    margin-top: 5px;
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.technologiesul > * {
    /* border: 1px solid gray; */
    border-radius: 4px;
    background-color: rgba(128, 128, 128, 0.295);
    width: fit-content;
    padding: 4px;

}
.actionContainer{
    /* transform: translateY(80px); */
    transition: all .3s ease-in-out;
    opacity: 0;
    display: flex;
    justify-content: center;
}

.card .view {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 1.5;
    font-weight: bold;
    opacity: 0;
    transition: all .3s ease-in-out;
    padding: 25px;
}

.card:hover .view {
    opacity: 1;
}

.card p{
    padding: 0 5px 0 5px;
}

a {
    color: rgb(124, 124, 124);
}
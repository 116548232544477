.reactionbarContainer{
    width: 100%;
    display: flex;
    justify-content: center;
}


.reactionbar {
    display: flex;
    gap: 12px;
    width: 800px;
    padding: 3px;
    padding-left: 50px;
    margin-top: 13px;
    border-radius: 50px;
    border: 1px solid rgb(160, 160, 160);
    background-color: rgb(230, 230, 230);
}

.reactionbar button{
    display: flex;
    align-items: center;
    gap: 4px;
    border: none;
    outline: none;
    padding: 8px;
    font-size: 1em;
    cursor: pointer;
    animation-fill-mode:forwards;
    background: none;
}

.reactionbar button.clicked{
    color: blue;
}

@keyframes clicked {
    0% {
        color: black;
    }
    100%{
        color: blue;
    }
    
}

.viewsCounter {
    display: flex;
    align-items: center;
    gap: 4px;
}

.reactionbar a{
    display: flex;
    gap: 4px;
    align-items: center;
    color: black;
    text-decoration: none;
}

.topbar{
    background: none;
    padding: 0;
    margin: 0;
}

.topbar div{
    background: none;
    padding: 0;
    color: white;
    border: none;
    margin: 0;
}

.topbar a{
    color: white;
}

.topbar div button {
    color: white;
}

